import { images } from "./images"

export const languages = ["RO", "EN", "DE", "FR", "HU"]

export const navigationButtons = [
	"homepage.menuButtons.home",
	"homepage.menuButtons.aboutUs",
	"homepage.menuButtons.accommodation",
	"homepage.menuButtons.activities",
	"homepage.menuButtons.gallery",
	"homepage.menuButtons.contact",
]

export const facilities = [
	{
		icon: images.internet,
		text: "homepage.facilities.internet",
	},
	{
		icon: images.jacuzzi,
		text: "JACUZZI",
	},
	{
		icon: images.sauna,
		text: "homepage.facilities.sauna",
	},
	{
		icon: images.parking,
		text: "homepage.facilities.parking",
	},
	{
		icon: images.salt,
		text: "homepage.facilities.salt",
	},
	{
		icon: images.bbq,
		text: "homepage.facilities.bbq",
	},
	{
		icon: images.ski,
		text: "homepage.facilities.ski",
	},
	{
		icon: images.living,
		text: "homepage.facilities.living",
	},
	{
		icon: images.terrace,
		text: "homepage.facilities.terrace",
	},
	{
		icon: images.garden,
		text: "homepage.facilities.garden",
	},
]

export const rooms = [
	{
		title: "homepage.rooms.cashmere.title",
		image: images.rooms.cashmere,
		description: "homepage.rooms.cashmere.description",
		noOfPeople: "2",
		roomType: "homepage.rooms.room",
		facilities: [
			"homepage.rooms.minibar",
			"homepage.rooms.tv",
			"homepage.rooms.hygiene",
			"homepage.rooms.shower",
			"homepage.rooms.mountainView",
			"homepage.rooms.dryer",
			"homepage.rooms.bathrobe",
		],
		actionButton: "homepage.activities.reserveButton",
		themeColor: "rgba(205, 154, 73, 1)",
	},
	{
		title: "homepage.rooms.emerald.title",
		image: images.rooms.emerald,
		description: "homepage.rooms.emerald.description",
		noOfPeople: "2",
		roomType: "homepage.rooms.room",
		facilities: [
			"homepage.rooms.minibar",
			"homepage.rooms.tv",
			"homepage.rooms.hygiene",
			"homepage.rooms.shower",
			"homepage.rooms.mountainView",
			"homepage.rooms.dryer",
			"homepage.rooms.bathrobe",
		],
		actionButton: "homepage.activities.reserveButton",
		themeColor: "rgba(16, 49, 42, 1)",
	},
	{
		title: "homepage.rooms.lagoon.title",
		image: images.rooms.lagoon,
		description: "homepage.rooms.lagoon.description",
		noOfPeople: "2-4",
		roomType: "homepage.rooms.room",
		facilities: [
			"homepage.rooms.minibar",
			"homepage.rooms.tv",
			"homepage.rooms.hygiene",
			"homepage.rooms.shower",
			"homepage.rooms.gardenView",
			"homepage.rooms.dryer",
			"homepage.rooms.bathrobe",
			"homepage.rooms.balcony",
		],
		actionButton: "homepage.activities.reserveButton",
		themeColor: "rgba(107, 121, 106, 1)",
	},
]

export const activities = [
	{
		image: images.activities.jeep,
		title: "homepage.activities.jeep.title",
		description: "homepage.activities.jeep.description",
		tag: "atv",
	},
	{
		image: images.activities.hiking,
		title: "homepage.activities.hiking.title",
		description: "homepage.activities.hiking.description",
		tag: "hiking",
	},
	{
		image: images.activities.ski,
		title: "homepage.activities.ski.title",
		description: "homepage.activities.ski.description",
		tag: "ski",
	},
]

export const contactInfo = [
	{
		icon: images.location,
		text: "Strada Dragoș Vodă 113 A,\nBaia Sprie",
	},
	{
		icon: images.phone,
		text: "+40 742 228 792",
	},
	{
		icon: images.mail,
		text: "casacerbuluimm@gmail.com",
	},
]

export const photoGallery = [
	require("../assets/gallery/Photo1.jpg"),
	require("../assets/gallery/Photo2.jpg"),
	require("../assets/gallery/Photo3.jpg"),
	require("../assets/gallery/Photo4.jpg"),
	require("../assets/gallery/Photo5.jpg"),
	require("../assets/gallery/Photo6.jpg"),
	require("../assets/gallery/Photo7.jpg"),
	require("../assets/gallery/Photo8.jpg"),
	require("../assets/gallery/Photo9.jpg"),
	require("../assets/gallery/Photo10.jpg"),
	require("../assets/gallery/Photo11.jpg"),
	require("../assets/gallery/Photo12.jpg"),
	require("../assets/gallery/Photo13.jpg"),
	require("../assets/gallery/Photo14.jpg"),
	require("../assets/gallery/Photo15.jpg"),
	require("../assets/gallery/Photo16.jpg"),
	require("../assets/gallery/Photo17.jpg"),
	require("../assets/gallery/Photo18.jpg"),
	require("../assets/gallery/Photo19.jpg"),
	require("../assets/gallery/Photo20.jpg"),
	require("../assets/gallery/Photo21.jpg"),
	require("../assets/gallery/Photo22.jpg"),
	require("../assets/gallery/Photo23.jpg"),
	require("../assets/gallery/Photo24.jpg"),
	require("../assets/gallery/Photo25.jpg"),
	require("../assets/gallery/Photo26.jpg"),
	require("../assets/gallery/Photo27.jpg"),
	// require("../assets/gallery/Photo28.jpg"),
	require("../assets/gallery/Photo29.jpg"),
	require("../assets/gallery/Photo30.jpg"),
	require("../assets/gallery/Photo31.jpg"),
	require("../assets/gallery/Photo32.jpg"),
	// require("../assets/gallery/Photo33.jpg"),
	// require("../assets/gallery/Photo34.jpg"),
	// require("../assets/gallery/Photo35.jpg"),
	// require("../assets/gallery/Photo36.jpg"),
	// require("../assets/gallery/Photo37.jpg"),
	// require("../assets/gallery/Photo38.jpg"),
	// require("../assets/gallery/Photo39.jpg"),
	// require("../assets/gallery/Photo40.jpg"),
	// require("../assets/gallery/Photo41.jpg"),
	// require("../assets/gallery/Photo42.jpg"),
	// require("../assets/gallery/Photo43.jpg"),
	// require("../assets/gallery/Photo44.jpg"),
	// require("../assets/gallery/Photo45.jpg"),
	// require("../assets/gallery/Photo46.jpg"),
	// require("../assets/gallery/Photo47.jpg"),
	// require("../assets/gallery/Photo48.jpg"),
	// require("../assets/gallery/Photo49.jpg"),
	// require("../assets/gallery/Photo50.jpg"),
	// require("../assets/gallery/Photo51.jpg"),
	// require("../assets/gallery/Photo52.jpg"),
	// require("../assets/gallery/Photo53.jpg"),
	// require("../assets/gallery/Photo54.jpg"),
	// require("../assets/gallery/Photo55.jpg"),
	// require("../assets/gallery/Photo56.jpg"),
	// require("../assets/gallery/Photo57.jpg"),
	// require("../assets/gallery/Photo58.jpg"),
	// require("../assets/gallery/Photo59.jpg"),
	// require("../assets/gallery/Photo60.jpg"),
	// require("../assets/gallery/Photo61.jpg"),
	// require("../assets/gallery/Photo62.jpg"),
	// require("../assets/gallery/Photo63.jpg"),
	// require("../assets/gallery/Photo64.jpg"),
	// require("../assets/gallery/Photo65.jpg"),
	// require("../assets/gallery/Photo66.jpg"),
	// require("../assets/gallery/Photo67.jpg"),
	// require("../assets/gallery/Photo68.jpg"),
	// require("../assets/gallery/Photo69.jpg"),
	// require("../assets/gallery/Photo60.jpg"),
	// require("../assets/gallery/Photo71.jpg"),
	// require("../assets/gallery/Photo72.jpg"),
	// require("../assets/gallery/Photo73.jpg"),
	// require("../assets/gallery/Photo74.jpg"),
	// require("../assets/gallery/Photo75.jpg"),
	// require("../assets/gallery/Photo76.jpg"),
	// require("../assets/gallery/Photo77.jpg"),
	// require("../assets/gallery/Photo78.jpg"),
	// require("../assets/gallery/Photo79.jpg"),
	// require("../assets/gallery/Photo80.jpg"),
	// require("../assets/gallery/Photo81.jpg"),
	// require("../assets/gallery/Photo82.jpg"),
	// require("../assets/gallery/Photo83.jpg"),
	// require("../assets/gallery/Photo84.jpg"),
	// require("../assets/gallery/Photo85.jpg"),
	// require("../assets/gallery/Photo86.jpg"),
	// require("../assets/gallery/Photo87.jpg"),
	// require("../assets/gallery/Photo88.jpg"),
	// require("../assets/gallery/Photo89.jpg"),
	// require("../assets/gallery/Photo90.jpg"),
	// require("../assets/gallery/Photo91.jpg"),
	// require("../assets/gallery/Photo92.jpg"),
	// require("../assets/gallery/Photo93.jpg"),
	// require("../assets/gallery/Photo94.jpg"),
	// require("../assets/gallery/Photo95.jpg"),
	// require("../assets/gallery/Photo96.jpg"),
	// require("../assets/gallery/Photo97.jpg"),
	// require("../assets/gallery/Photo98.jpg"),
	// require("../assets/gallery/Photo99.jpg"),
]

export const atvOptions = [
	"activities.jeep.options.helmet",
	"activities.jeep.options.gloves",
	"activities.jeep.options.fuel",
	"activities.jeep.options.guide",
	"activities.jeep.options.stops",
]

export const jeepOptions = [
	"activities.jeep.options.fuel",
	"activities.jeep.options.stops",
	"activities.jeep.options.guide",
]

export const tabs = [
	{
		icon: require("../assets/activities/tabs/jeep.png"),
		iconSelected: require("../assets/activities/tabs/jeepSelected.png"),
		text: "activities.tabs.jeep",
		tag: "atv",
	},
	{
		icon: require("../assets/activities/tabs/hiking.png"),
		iconSelected: require("../assets/activities/tabs/hikingSelected.png"),
		text: "activities.tabs.hiking",
		tag: "hiking",
	},
	{
		icon: require("../assets/activities/tabs/ski.png"),
		iconSelected: require("../assets/activities/tabs/skiSelected.png"),
		text: "activities.tabs.ski",
		tag: "ski",
	},
]

export const hikingLocations = [
	{
		title: "activities.hiking.lake.title",
		image: require("../assets/activities/hiking1.png"),
		imageTablet: require("../assets/activities/tablet/hiking1.png"),
		description: "activities.hiking.lake.description",
		duration: "activities.hiking.lake.duration",
		difficulty: "activities.hiking.lake.difficulty",
		marking: "activities.hiking.lake.marking",
		number: require("../assets/activities/01.png"),
	},
	{
		title: "activities.hiking.theCrest.title",
		image: require("../assets/activities/hiking2.png"),
		imageTablet: require("../assets/activities/tablet/hiking2.png"),
		description: "activities.hiking.theCrest.description",
		duration: "activities.hiking.theCrest.duration",
		difficulty: "activities.hiking.theCrest.difficulty",
		marking: "activities.hiking.theCrest.marking",
		number: require("../assets/activities/02.png"),
	},
	{
		title: "activities.hiking.suior.title",
		image: require("../assets/activities/hiking3.png"),
		imageTablet: require("../assets/activities/tablet/hiking3.png"),
		description: "activities.hiking.suior.description",
		duration: "activities.hiking.suior.duration",
		difficulty: "activities.hiking.suior.difficulty",
		marking: "activities.hiking.suior.marking",
		number: require("../assets/activities/03.png"),
	},
	{
		title: "activities.hiking.ignis.title",
		image: require("../assets/activities/hiking4.png"),
		imageTablet: require("../assets/activities/tablet/hiking4.png"),
		description: "activities.hiking.ignis.description",
		duration: "activities.hiking.ignis.duration",
		difficulty: "activities.hiking.ignis.difficulty",
		marking: "activities.hiking.ignis.marking",
		number: require("../assets/activities/04.png"),
	},
]

export const skilocations = [
	{
		title: "activities.ski.cavnic.title",
		image: require("../assets/activities/ski1.png"),
		imageTablet: require("../assets/activities/tablet/ski1.png"),
		description:"activities.ski.cavnic.description",
		distance:"activities.ski.cavnic.distance",
		number: require("../assets/activities/01.png"),
	},
	{
		title: "activities.ski.suior.title",
		image: require("../assets/activities/ski2.png"),
		imageTablet: require("../assets/activities/tablet/ski2.png"),
		description:"activities.ski.suior.description",
		distance:"activities.ski.suior.distance",
		number: require("../assets/activities/02-ski.png"),
	},
]

export const cavnicTracks = [
	{
		name: "Roata 1",
		difficulty: "activities.ski.medium",
		length: "920 m",
	},
	{
		name: "Roata 2",
		difficulty: "activities.ski.easy",
		length: "1030 m",
	},
	{
		name: "Rainer 1 (Roata 3)",
		difficulty: "activities.ski.hard",
		length: "800 m",
	},
	{
		name: "Rainer 2 (Roata 4)",
		difficulty: "activities.ski.hard",
		length: "1000 m",
	},
	{
		name: "Pârtia Albastră",
		difficulty: "activities.ski.easyMedium",
		length: "2250 m",
	},
	{
		name: "Pârtia Albastră (Roata 2)",
		difficulty: "activities.ski.easy",
		length: "160 m",
	},
	{
		name: "Rainer 1 (Roata 1)",
		difficulty: "activities.ski.easy",
		length: "140 m",
	},
]

export const suiorTracks = [
	{
		name: "Șuior 1",
		difficulty: "activities.ski.medium",
		length: "1500 m",
	},
	{
		name: "Șuior 2",
		difficulty: "activities.ski.medium",
		length: "1200 m",
	},
	{
		name: "Șuior 3",
		difficulty: "activities.ski.medium",
		length: "350 m",
	},
	{
		name: "Începători",
		difficulty: "activities.ski.easy",
		length: "150 m",
	},
	{
		name: "Moski",
		difficulty: "activities.ski.hard",
		length: "600 m",
	},
	{
		name: "Nocturnă",
		difficulty: "activities.ski.medium",
		length: "1500 m",
	},
]
