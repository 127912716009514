import { RefObject, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import styles from "./styles.module.css"
import { images } from "../../../constants/images"
import { navigationButtons, languages } from "../../../constants/arrays"

interface Props {
	setShowMenu: (arg: boolean) => void
}

interface MenuProps {
	refs?: RefObject<HTMLDivElement>[]
	scroll?: (scrollRef: RefObject<HTMLDivElement>) => void
	scrollToAbout?: () => void
	data: {
		type: string
		title: string
		subtitle: string
		image: string
	}
}

export const Menu: React.FC<Props & MenuProps> = ({
	refs,
	setShowMenu,
	scroll,
	scrollToAbout,
	data,
}) => {
	const navigation = useNavigate()
	const { t, i18n } = useTranslation()
	const [language, setLanguage] = useState<string>(i18n.language)
	const [show, setShow] = useState<boolean>(false)

	const handleLangChange = (lang: string) => {
		setLanguage(lang.toUpperCase())
		i18n.changeLanguage(lang.toLocaleLowerCase())
	}

	return (
		<div className={styles.navigationContainer}>
			<div className={styles.closeContainer}>
				<a onClick={() => setShowMenu(false)}>
					<img className={styles.close} src={images.close} />
				</a>
			</div>
			<div className={styles.buttonsContainer}>
				{navigationButtons.map((item: string, index: number) => (
					<a
						className={styles.navButton}
						onClick={() =>
							data.type === "home"
								? index === 1 && scrollToAbout
									? scrollToAbout()
									: scroll && refs
									? scroll(refs[index])
									: 0
								: navigation("/", {
										state: {
											ref: index,
										},
								  })
						}
					>
						{t(`${item}`)}
					</a>
				))}
				<div className={styles.separator} />
			</div>
			<div className={styles.langButtonsContainer}>
				<p className={styles.selectedContainer}>{language.toUpperCase()}</p>
				{languages.map((item: string) => (
					<a
						className={styles.buttons}
						onClick={() => {
							handleLangChange(item)
							setShow(false)
							setShowMenu(false)
						}}
					>
						{item.toUpperCase()}
					</a>
				))}
			</div>
		</div>
	)
}

const MobileNavigation: React.FC<Props> = ({ setShowMenu }) => {
	return (
		<div className={styles.container}>
			<div className={styles.iconContainer} />
			<div className={styles.logoContainer}>
				<img className={styles.logo} src={images.logo} />
			</div>
			<div className={styles.iconContainer}>
				<a
					className={styles.menuToggle}
					onClick={() => setShowMenu(true)}
				>
					<img className={styles.menuIcon} src={images.menuIcon} />
				</a>
			</div>
		</div>
	)
}

export default MobileNavigation
